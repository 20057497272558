import BaseComponent from "@/components/base/el/BaseComponent";
// import BaseResize from "@/components/base/el/BaseResize";

export default {
    name: "BaseHTMLContentComponent",
    mixins: [
      // BaseResize,
      BaseComponent
    ],
    data() {
        return {
          window_width: window.innerWidth,
        };
    },
    mounted() {
      
    },
    render(createElement) {
      let tag = this.component_data.tag;
      let data = this.data_object;
      // let children = [];
      // children = this.get_children_resize(createElement)
  
      if (this.events && this.events.click["apply-filter"]) {
        let [filter_key, filter_value] = this.events.click["apply-filter"].split('=')
        const url = new URL(window.location);
        url.searchParams.get(filter_key);
        if (url.searchParams.get(filter_key) == filter_value) {
          data.class = [...data.class, ...['active']]
        }
      }
      
      // children = children.concat([
      //   createElement('div', {
      //     domProps: {
      //       innerHTML: unescape(innerHTML)
      //     },
      //     style: "display: contents"
      //   })
      // ])
      return createElement(tag, data)
      // console.log([content_html].concat(children));
  
      // return createElement(
      //     tag,
      //     data,
      //     content_html.concat(children)
      // )
      // return createElement(tag, data, children);
    },
    computed: {
      innerHTML() {
        return this.component_data.innerHTML
      },
      // mobile_flex() {
      //   return this.component_data.attr.style.flex_data.mobile;
      // },
      // tablet_flex() {
      //   return this.component_data.attr.style.flex_data.tablet;
      // },
      // desktop_flex() {
      //   return this.component_data.attr.style.flex_data.desktop;
      // },
      // desktop_size() {
      //   return this.component_data.attr.style.size.desktop
      // },
      // tablet_size() {
      //   return this.component_data.attr.style.size.tablet
      // },
      // mobile_size() {
      //   return this.component_data.attr.style.size.mobile
      // },
      // padding_data_top() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].top;
      //   }
      // },
      // padding_data_right() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].right;
      //   }
      // },
      // padding_data_bottom() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].bottom;
      //   }
      // },
      // padding_data_left() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.padding_data || !this.component_data.attr.style.padding_data[reponsive]) return null
      //     return this.component_data.attr.style.padding_data[reponsive].left;
      //   }
      // },
      // margin_data_left() {
      //   return (reponsive) => {
      //     if (!this.component_data.attr.style.margin_data || !this.component_data.attr.style.margin_data[reponsive]) return null
      //     return this.component_data.attr.style.margin_data[reponsive].left;
      //   }
      // },
      desktop_transform() {
        return this.component_data.attr.style.transform_data.desktop || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      tablet_transform() {
        return this.component_data.attr.style.transform_data.tablet || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      mobile_transform() {
        return this.component_data.attr.style.transform_data.mobile || 'matrix(1, 0, 0, 1, 0, 0)';
      },
      styling() {
        if (!this.component_data.attr.style) return {}
  
        let styling = JSON.parse(JSON.stringify(this.component_data.attr.style))
        delete styling.transform_data
        let transform
        // ,flex, size, padding_top, padding_right, padding_bottom, padding_left, margin_left
        if (this.window_width < 600) {
          transform = this.mobile_transform
          // flex = this.mobile_flex
          // size = this.mobile_size
          // padding_top = this.padding_data_top('mobile')
          // padding_right = this.padding_data_right('mobile')
          // padding_bottom = this.padding_data_bottom('mobile')
          // padding_left = this.padding_data_left('mobile')
          // margin_left = this.margin_data_left('mobile')
        } else if (this.window_width < 992) {
          transform = this.tablet_transform
          // padding_top = this.padding_data_top('tablet')
          // padding_right = this.padding_data_right('tablet')
          // padding_bottom = this.padding_data_bottom('tablet')
          // padding_left = this.padding_data_left('tablet')
          // margin_left = this.margin_data_left('tablet')
          // flex = this.tablet_flex
          // size = this.tablet_size
        } else {
          transform = this.desktop_transform
          // padding_top = this.padding_data_top('desktop')
          // padding_right = this.padding_data_right('desktop')
          // padding_bottom = this.padding_data_bottom('desktop')
          // padding_left = this.padding_data_left('desktop')
          // margin_left = this.margin_data_left('desktop')
          // flex = this.desktop_flex
          // size = this.desktop_size
        }
        if (transform) styling.transform = transform
        // if (flex) styling.flex = flex + ' !important'
        // if (size) {
        //   styling.width = size[0] + 'px !important'
        //   styling.height = size[1] + 'px !important'
        // }
        // if (padding_top !== undefined) styling.paddingTop = padding_top + 'px !important'
        // if (padding_right !== undefined) styling.paddingRight = padding_right + 'px !important'
        // if (padding_bottom !== undefined) styling.paddingBottom = padding_bottom + 'px !important'
        // if (padding_left !== undefined) styling.paddingLeft = padding_left + 'px !important'
        // if (margin_left !== undefined) styling.marginLeft = margin_left + 'px !important'
  
        return styling
      },
      data_object() {
        let class_array = []
        if (this.$store.state.edit_mode) {
          class_array = [{
            "deepsel-focus": this.is_editing && !this.$store.state.editing_text,
            "deepsel-text-edit": this.is_editing && this.$store.state.editing_text
          }]
        }
        
        if (this.component_data.attr && this.component_data.attr.class) {
          class_array.push(this.component_data.attr.class)
        }
      
        let res = {
          class: class_array,
          attrs: {
            "data-id": this.data_id,
            contenteditable: this.contenteditable,
            draggable: false,
          },
          domProps: {
            innerHTML: unescape(this.innerHTML)
          },
          style: this.styling
        }
      
        if (this.$store.state.edit_mode) {
          res = {
            ...res,
            on: {
              mouseenter: this.mouse_enter,
              mousedown: this.mouse_down,
            }
          }
        }
  
        return res
      },
  
    },
    methods: {
        toggle_edit_text() {
            this.$store.state.editing_html_content = !this.$store.state.editing_html_content
        },
        save(new_content) {
          console.log(new_content);
            this.component_data.innerHTML = new_content
        }
    },
}